// hooks
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import siteActions from '../store/Site/actions';

// selectors
import siteSelectors from '../store/Site/selectors';


const useSite = () => {
  const site = useSelector(siteSelectors.getSite);
  const images = useSelector(siteSelectors.getImages);
  const cesiumLayers = useSelector(siteSelectors.getCesiumLayers);
  const assetGroups = useSelector(siteSelectors.getAssetGroups);
  const loading = useSelector(siteSelectors.isLoading);
  const loadingImages = useSelector(siteSelectors.isLoadingImages);

  const error = useSelector(siteSelectors.getError);
  const currentSiteAssessment = useSelector(siteSelectors.getCurrentSiteAssessment);
  const publicCesiumLayerSettingsId = useSelector(siteSelectors.getPublicCesiumLayerSettingsId);
  const privateCesiumLayerSettingsId = useSelector(siteSelectors.getPrivateCesiumLayerSettingsId);
  const userProfile = useSelector(siteSelectors.getUserProfile);

  const dispatch = useDispatch();

  const fetchSite = useCallback(
    (assessmentId?: string) => {
      dispatch(siteActions.fetchSite(assessmentId));
    },
    [dispatch]
  );

  const fetchPhotos = useCallback(
    (assessmentId?: string) => {
      dispatch(siteActions.fetchPhotos(assessmentId));
    },
    [dispatch]
  );

  const fetchCesiumLayers = useCallback(
    (assessmentId?: string, siteListMemberId?: string) => {
      dispatch(siteActions.fetchCesiumLayers(assessmentId, siteListMemberId));
    },
    [dispatch]
  );

  const saveCesiumLayers = useCallback(
    (
      assessmentId: string,
      publicCesiumLayerSettingsId: string | undefined,
      privateCesiumLayerSettingsId: string | undefined,
      savePublicAllowed: boolean,
      payload: any
    ) => {
      dispatch(siteActions.saveCesiumLayers(assessmentId, publicCesiumLayerSettingsId, privateCesiumLayerSettingsId, savePublicAllowed, payload));
    },
    [dispatch]
  );

  const setCurrentSiteAssessmentId = useCallback(
    (siteAssessmentId: string) => {
      dispatch(siteActions.setCurrentSiteAssessmentId(siteAssessmentId));
    },
    [dispatch]
  );

 
  const clearState = useCallback(() => {
    dispatch(siteActions.clearState());
  }, [dispatch]);


  return {
    site,
    images,
    loading,
    loadingImages,
    error,
    fetchSite,
    fetchPhotos,
    clearState,
    fetchCesiumLayers,
    saveCesiumLayers,
    cesiumLayers,
    currentSiteAssessment,
    setCurrentSiteAssessmentId,
    publicCesiumLayerSettingsId,
    privateCesiumLayerSettingsId,
    userProfile,
    assetGroups,
  };
};

export default useSite;
