import { SiteRoot } from "../../@types";

export enum SiteActionType {
  FETCH_SITE = 'Site@FETCH_SITE',
  FETCH_PHOTOS = 'Site@FETCH_PHOTOS',
  SET_SITE = 'Site@SET_SITE',
  SET_CURRENT_ASSESSMENT_ID = 'Site@SET_CURRENT_ASSESSMENT_ID',
  SET_CURRENT_ASSESSMENT_PUBLIC_CESIUM_LAYER_SETTINGS_ID = 'Site@SET_CURRENT_ASSESSMENT_PUBLIC_CESIUM_LAYER_SETTINGS_ID',
  SET_CURRENT_ASSESSMENT_PRIVATE_CESIUM_LAYER_SETTINGS_ID = 'Site@SET_CURRENT_ASSESSMENT_PRIVATE_CESIUM_LAYER_SETTINGS_ID',
  SET_CURRENT_IMAGES = 'Site@SET_CURRENT_IMAGES',
  SET_LOADING = 'Site@SET_LOADING',
  SET_LOADING_IMAGES = 'Site@SET_LOADING_IMAGES',
  SET_USER_PROFILE = 'Site@SET_USER_PROFILE',
  SET_ASSET_GROUPS = 'Site@SET_ASSET_GROUPS',
  SET_ERROR = 'Site@SET_ERROR',
  CLEAR = 'Site@CLEAR',
  SET_CESIUM_LAYERS = 'Site@SET_CESIUM_LAYERS',
  FETCH_CESIUM_LAYERS = 'Site@FETCH_CESIUM_LAYERS',
  SAVE_CESIUM_LAYERS = 'Site@SAVE_CESIUM_LAYERS',
}

export type SiteState = {
  site?: SiteRoot;
  cesium?: any;
  images?: any;
  loading: boolean;
  loadingImages: boolean;
  error: undefined | Error;
  currentSiteAssessmentId: string | undefined;
  privateCesiumLayerSettingsId: string | undefined;
  publicCesiumLayerSettingsId: string | undefined;
  userProfile: any;
  assetGroups: any;
};

export type SitePayload = {
  site?: SiteRoot;
  loading?: boolean;
  loadingImages?: boolean;
  error?: Error;
  assessmentId?: string;
  cesiumLayersId?: string;
  siteId?: string;
  currentSiteAssessmentId?: string;
  publicCesiumLayerSettingsId?: string;
  privateCesiumLayerSettingsId?: string;
  layerSettingsId?: string | undefined;
  cesium?: any;
  images?: any;
  userProfile?: any;
  savePublicAllowed?: boolean;
  assetGroups?: any;
  siteListMemberId?: string;
};

export type SiteAction = {
  payload?: SitePayload;
  type: SiteActionType;
};

export type SiteReducer = (state: SiteState, payload?: SitePayload) => SiteState;
