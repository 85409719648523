import { ApplicationState } from '../types';
import { SiteAssessment } from '../../@types';


const _getData = (state: ApplicationState) => state.Site;

const getError = (state: ApplicationState) => _getData(state).error;

const isLoading = (state: ApplicationState) => _getData(state).loading;

const isLoadingImages = (state: ApplicationState) => _getData(state).loadingImages;

const getSite = (state: ApplicationState) => _getData(state).site;

const getImages = (state: ApplicationState) => _getData(state).images;

const getCesiumLayers = (state: ApplicationState) => _getData(state).cesium;

const getAssetGroups = (state: ApplicationState) => _getData(state).assetGroups;

const getUserProfile = (state: ApplicationState) => _getData(state).userProfile;

const getCurrentSiteAssessmentId = (state: ApplicationState) =>
  _getData(state).currentSiteAssessmentId;
const getPublicCesiumLayerSettingsId = (state: ApplicationState) =>
  _getData(state).publicCesiumLayerSettingsId;
const getPrivateCesiumLayerSettingsId = (state: ApplicationState) =>
  _getData(state).privateCesiumLayerSettingsId;


const getCurrentSiteAssessment = (state: ApplicationState) => {
  const siteRoot = _getData(state).site;
  const currentSiteAssessmentId = getCurrentSiteAssessmentId(state);

  if (!siteRoot || !siteRoot.siteAssessments || !currentSiteAssessmentId) {
    return;
  }

  return siteRoot.siteAssessments.find(
    (siteAssessment: SiteAssessment) => siteAssessment.id === currentSiteAssessmentId
  );
};


const selectors = {
  getError,
  isLoading,
  isLoadingImages,
  getSite,
  getImages,
  getCesiumLayers,
  getAssetGroups,
  getCurrentSiteAssessment,
  getCurrentSiteAssessmentId,
  getPublicCesiumLayerSettingsId,
  getPrivateCesiumLayerSettingsId,
  getUserProfile,
};

export default selectors;
